export const environment = {
  production: false,
  cognitoConfig: {
    userPoolId: "eu-west-2_3J9uwiJjm",
    userPoolClientId: "52tjdpv066unkb1lhbaa2df649",
    loginWith: {
      email: true,
      oauth: {
        domain: "dev-cloud.auth.eu-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: ["http://localhost:4200", "https://dev.cloud.incline-it.com"],
        redirectSignOut: ["http://localhost:4200", "https://dev.cloud.incline-it.com"],
        responseType: 'code' as ('code' | 'token')
      }
    }
  },
  licensing_server: "https://iyj3ipjim2.execute-api.eu-west-2.amazonaws.com/Prod/"
};