import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILsApplicationsResponse } from 'src/app/models/licenseServer/ls-applications.response.model';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { LoginService } from 'login';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(
    private readonly http: HttpClient,
    private readonly loginService: LoginService) { }

  public async getApplications(): Promise<ILsApplicationsResponse> {
    const headers = await this.loginService.getHttpAuthorisationHeaders();
    return await lastValueFrom(this.http.get<ILsApplicationsResponse>(environment.licensing_server + 'Applications', { headers: headers }));
  }
}