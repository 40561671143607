import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule, LOGIN_CONFIG } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HubComponent } from './pages/hub/hub.component';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import {
  AppLauncherComponent,
  TitleBarComponent,
  FooterComponent,
  InputComponent,
  CardComponent,
  ButtonComponent,
  LoadingComponent,
  PageComponent
} from 'mis-component-library';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { LoadingComponent as AppLoadingComponent } from './pages/loading/loading.component';
import { LoginModule } from 'login';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    HubComponent,
    AppComponent
  ],
  imports: [
    AppLauncherComponent,
    TitleBarComponent,
    FooterComponent,
    InputComponent,
    CardComponent,
    ButtonComponent,
    AppLoadingComponent,
    LoadingComponent,
    LoginModule.forRoot({
      licenseServerUrl: environment.licensing_server,
      pathConfig: LOGIN_CONFIG
    }),
    PageComponent,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(handler: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(handler, [
    { prefix: './assets/i18n/', suffix: '.json' }
  ])
}