import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, Router, UrlTree } from '@angular/router';
import { HubComponent } from 'src/app/pages/hub/hub.component';
import { LoginRoutingModule, LoginService } from 'login';
import { LoginPathConfig } from 'login/lib/types/login-config';

export const LOGIN_CONFIG: LoginPathConfig = {
  loginPath: 'login',
  newPasswordPath: 'login/new-password'
};

// Can only activate the hub if the user is signed in.
async function canActivateApp(): Promise<boolean | UrlTree> {
  const router = inject(Router);
  const loginService = inject(LoginService);
  return await loginService.getUserEmail() ? true : router.createUrlTree(['login']);
}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'hub' },
  { path: 'hub', component: HubComponent, canActivate: [canActivateApp], runGuardsAndResolvers: 'always' },
  ...LoginRoutingModule.getRoutes(LOGIN_CONFIG)
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }