import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import { CookieStorage, Hub } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

if (environment.production) {
  enableProdMode();
}

Hub.listen('auth', (data) => {
  // User has just been authenticated. Check to see if redirect url was provided and, if so, redirect.
  if (data.payload.event != 'customOAuthState' || !data.payload.data) { return; }
  window.location.href = data.payload.data;
});

// Configure amplify
Amplify.configure({ Auth: { Cognito: environment.cognitoConfig } });
// eslint-disable-next-line no-magic-numbers
const rootDomain = location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ sameSite: 'none', domain: rootDomain, secure: true }));

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));