import { Component, OnInit } from '@angular/core';
import { LoginService } from 'login';
import { ILsApplication } from 'src/app/models/licenseServer/ls-application.model';
import { ApplicationsService } from 'src/app/services/applications/applications.service';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})
export class HubComponent implements OnInit {
  protected applications?: ILsApplication[];

  constructor(
    private readonly applicationsService: ApplicationsService,
    private readonly loginService: LoginService) { }

  public async ngOnInit(): Promise<void> {
    const response = await this.applicationsService.getApplications();
    this.applications = response.applications;
  }

  protected async logout(): Promise<void> {
    await this.loginService.signOut();
  }
}