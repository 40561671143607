<div class="app-hub">
  <mis-title-bar color="primary-dark">
    <ng-container startSlot>
      <a href="https://www.incline-it.com" class="clickable incline-link" title="Link and Logo for Incline-IT"></a>
    </ng-container>
    <ng-container endSlot>
      <div class="title-bar-element-padding">
        <a class="clickable help" href="https://incline.atlassian.net" title="Help"> </a>
        <button id="logout" type="button" class="logout clickable" (click)="logout()">
          <span class="logout-text">Log Out</span>
          <div class="icon"></div>
        </button>
      </div>
    </ng-container>
  </mis-title-bar>
  <div class="content">
    <app-loading *ngIf="!applications"></app-loading>
    <span *ngIf="applications" class="title">Available Applications</span>
    <div class="app-grid">
      <mis-app-launcher *ngFor="let application of applications"
        appImageUrl="./assets/icons/{{application.applicationName}}.png" [appLaunchUrl]="application.url"
        [appTitle]="application.applicationName"></mis-app-launcher>
    </div>
  </div>
</div>